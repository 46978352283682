import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import OnPremiseConnectorsService from '../../../services/OnPremiseConnectors.service'

export const useAddConnector = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()

  // To do: change parameters type
  return useMutation<
    any,
    Error,
    {
      body: {
        name: string
        configurationType: string
        parameters: {
          ovpnFile: string
        }
      }
    }
  >({
    mutationKey: ['add-connector'],
    mutationFn: (payload) => OnPremiseConnectorsService.addOnPremiseConnector(org!, payload.body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['on-premise-connectors'] })
    },
  })
}
