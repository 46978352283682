import { axiosConnectorsInstance } from './http-common'

class OnPremiseConncetorsService {
  async listOnPremiseConnectors(
    organization: string,
    page: number,
    size: number,
    name?: string,
    types?: string[],
    states?: string[],
  ) {
    return (
      await axiosConnectorsInstance.get(`${organization}/connectors`, {
        params: {
          page: page,
          size: size,
          name: name,
          types: types,
          states: states,
        },
        paramsSerializer: { indexes: null },
      })
    ).data
  }

  async getConnectorById(organization: string, id: string) {
    return (await axiosConnectorsInstance.get(`${organization}/connectors/${id}`)).data
  }

  // TO DO: change parameters type
  addOnPremiseConnector = async (
    organization: string,
    body: {
      name: string
      configurationType: string
      parameters: {
        ovpnFile: string
      }
    },
  ): Promise<any> => {
    return (await axiosConnectorsInstance.post(`/${organization}/connectors`, body)).data
  }

  async deleteOnPremiseConnector(organization: string, id: string) {
    return (await axiosConnectorsInstance.delete(`/${organization}/connectors/${id}`)).data
  }

  async provisionConnector(id: string, organization: string) {
    return (await axiosConnectorsInstance.post(`/${organization}/connectors/${id}`)).data
  }

  // TO DO: change parameters type
  async updateConnectorConfig(
    id: string,
    organization: string,
    body: {
      configurationType: string
      parameters: {
        ovpnFile: string
      }
    },
  ) {
    return (await axiosConnectorsInstance.post(`/${organization}/connectors/${id}/configuration`, body)).data
  }

  async editConnectorName(organization: string, connectorId: string, name: string) {
    return (await axiosConnectorsInstance.post(`/${organization}/connectors/${connectorId}/name`, { name: name })).data
  }

  //TO DO: check and implement
  async getConfiguration(type: string) {
    return (await axiosConnectorsInstance.get(`/configuration/${type}`)).data
  }

  //Links

  addLink = async (
    organization: string,
    id: string,
    body: {
      name: string
      path: string
      host: string
      fqdn: string
    },
  ): Promise<any> => {
    return (await axiosConnectorsInstance.post(`/${organization}/connectors/${id}/links`, body)).data
  }

  async deleteLink(organization: string, connectorId: string, linkId: string) {
    return (await axiosConnectorsInstance.delete(`/${organization}/connectors/${connectorId}/links/${linkId}`)).data
  }

  async updateLink(organization: string, connectorId: string, linkId: string, field: string, body: any) {
    return (
      await axiosConnectorsInstance.post(`/${organization}/connectors/${connectorId}/links/${linkId}/${field}`, body)
    ).data
  }
}

export default new OnPremiseConncetorsService()
