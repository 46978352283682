import { useMutation, useQueryClient } from '@tanstack/react-query'
import OnPremiseConnectorsService from '../../../services/OnPremiseConnectors.service'

type UpdateConnectorPayload = {
  id: string
  organization: string
  // TO DO: change parameters type
  body: {
    configurationType: string
    parameters: {
      ovpnFile: string
    }
  }
}

export const useUpdateConnector = () => {
  const queryClient = useQueryClient()

  return useMutation<any, Error, UpdateConnectorPayload>({
    mutationKey: ['connector-update'],
    mutationFn: (payload) =>
      OnPremiseConnectorsService.updateConnectorConfig(payload.id, payload.organization, payload.body),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['on-premise-connectors'] })
    },
  })
}
