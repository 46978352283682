import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import OnPremiseConnectorsService from '../../../services/OnPremiseConnectors.service'

export const useDeleteConnector = () => {
  const queryClient = useQueryClient()
  const { org } = useParams()

  return useMutation<any, Error, { id: string }>({
    mutationKey: ['delete-connector'],
    mutationFn: (payload) => OnPremiseConnectorsService.deleteOnPremiseConnector(org!, payload.id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['on-premise-connectors'] })
    },
  })
}
