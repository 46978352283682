import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { Button, ModalDialog } from '@sistemiv/s-components'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import LinkForm from './LinkForm'
import { Connector, Link } from '../../models/Connectors'
import OnPremiseConnectorsService from '../../services/OnPremiseConnectors.service'
import { useParams } from 'react-router-dom'

const LinksModal: FC<{
  open: boolean
  setOpen: DispatchSetAction<boolean>
  editingConnector: Connector
  onClose?: () => void
}> = ({ open, setOpen, onClose, editingConnector }) => {
  const { org } = useParams()
  const { t } = useTranslation()
  const [links, setLinks] = useState<Link[]>(editingConnector ? editingConnector.links : [])

  const handleCreateLink = (body, connectorId: string) => {
    OnPremiseConnectorsService.addLink(org!, connectorId, body).then((resp) => {
      setLinks((old) => [...old.slice(0, -1), { id: resp.linkId, ...body }])
    })
  }

  const handleDeleteLink = (connectorId: string, linkId: string) => {
    OnPremiseConnectorsService.deleteLink(org!, connectorId, linkId).then(() => {
      setLinks((old) => old.filter((link) => link.id !== linkId))
    })
  }

  return (
    <ModalDialog isOpen={open} setOpen={setOpen} className='!min-w-xl w-fit !max-w-[800px] max-h-full overflow-auto'>
      <Dialog.Title as='h3' className='leading-6 text-lg flex items-center justify-between'>
        {t('Connectors.links')}
        <button
          onClick={() => {
            setOpen(false)
            onClose && onClose()
          }}
          className='rounded-full p-2 hover:bg-gray-100 active:text-blue-500'
        >
          <XMarkIcon className='w-5 h-5 stroke-gray-500 active:stroke-blue-500' />
        </button>
      </Dialog.Title>
      <div className='py-2'>
        {links?.length > 0 ? (
          links.map((link) => (
            <>
              <LinkForm
                key={link.id}
                editingLink={link}
                connectorId={editingConnector.id}
                onCreate={handleCreateLink}
                onDelete={handleDeleteLink}
              />
            </>
          ))
        ) : (
          <LinkForm connectorId={editingConnector?.id} onCreate={handleCreateLink} />
        )}
        {links.length > 0 && (
          <Button
            onClick={() => setLinks((old) => [...old, { id: '', name: '', host: '', path: '', fqdn: '' }])}
            className='text-blue-600 mt-5'
          >
            + Link
          </Button>
        )}
      </div>
    </ModalDialog>
  )
}

export default LinksModal
