import React, { FC, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Button, DeleteModal, PaginationController, SpinnerIcon } from '@sistemiv/s-components'
import { useTranslation } from 'react-i18next'
import { PlusIcon } from '@heroicons/react/24/solid'
import { TableRow } from '../table/Table'
import Table from './Table'
import { useAddConnector } from '../../repositories/connectors/mutation/add-connector.mutation'
import { useOnPremiseConnectors } from '../../repositories/connectors'
import { useUpdateConnector } from '../../repositories/connectors/mutation/update-connector.mutation'
import { useDeleteConnector } from '../../repositories/connectors/mutation/delete-connector.mutation'
import AddConnectorModal from './AddConnectorModal'
import { Connector } from '../../models/Connectors'
import LinksModal from './LinksModal'
import EditConnectorModal from './EditConnectorModal'

// TO DO: check possible options for type
const options = ['ovpn']

const OnPremiseConnectorsPage: FC = () => {
  const { org } = useParams()
  const [page, setPage] = useState(0)
  const [size, setSize] = useState(20)
  const [createConnectorModalOpen, setCreateConnectorModalOpen] = useState(false)
  const [editConnectorModalOpen, setEditConnectorModalOpen] = useState(false)
  const [linksModalOpen, setLinksModalOpen] = useState(false)
  const [nameSearch, setNameSearch] = useState('')
  const { t } = useTranslation()
  const [tableFilters, setTableFilters] = useState<{ [key: string]: any }>({
    type: [],
    state: [],
  })
  const {
    data: connectors,
    refetch,
    isLoading,
    isPending,
  } = useOnPremiseConnectors({
    page: page,
    size: size,
    name: nameSearch,
    types: tableFilters['type'],
    states: tableFilters['state'].map((state) => state.value),
  })
  const { mutate: addConnector, isPending: addingConnector } = useAddConnector()
  const [editingConnector, setEditingConnector] = useState<Connector | undefined>(undefined)
  const { mutate: editConnector, isPending: updatingConnector } = useUpdateConnector()
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const { mutateAsync: deleteConnector, isPending: removingConnector } = useDeleteConnector()

  const newRows = connectors?.connectors?.map((acc) => {
    const retval: TableRow = {
      id: {
        type: 'plainText',
        value: acc?.id,
      },
      name: {
        type: 'plainText',
        value: acc?.name,
      },
      type: {
        type: 'plainText',
        value: acc?.type,
      },
      state: {
        type: 'plainText',
        value: acc?.state,
      },
    }

    return retval
  })

  const headers = [
    {
      accessor: 'name',
      label: t('Connectors.name'),
      searchType: 'search',
    },
    {
      accessor: 'type',
      label: t('Connectors.type'),
      searchType: '',
      // TO DO: implement after having more than one type of connector
      // searchType: 'select',
      // options: ['L2TP/IPSec', 'OpenVPN'],
    },
    {
      accessor: 'state',
      label: t('Connectors.state'),
      searchType: 'select',
      options: [
        { id: 'provisioned', name: 'Provisioned' },
        { id: 'unprovisioned', name: 'Unprovisioned' },
        { id: 'draft', name: 'Draft' },
      ],
    },
    {
      accessor: 'actions',
      label: t('Connectors.actions'),
      searchType: '',
    },
  ]

  //To DO: change when configuration fields are defined
  const handleCreateConnector = (name: string, type: string) => {
    addConnector(
      {
        body: {
          name: name,
          configurationType: type,
          parameters: {
            ovpnFile: '',
          },
        },
      },
      {
        onSuccess: () => {
          setCreateConnectorModalOpen(false)
        },
      },
    )
  }

  //To DO: change function to accept configuration values other than type
  const handleUpdateConnector = (type: string) => {
    if (!org || !editingConnector) return
    editConnector(
      {
        id: editingConnector!.id,
        organization: org!,
        body: {
          configurationType: type,
          parameters: {
            ovpnFile: '',
          },
        },
      },
      {
        onSuccess: () => {
          setEditConnectorModalOpen(false)
          setEditingConnector(undefined)
        },
      },
    )
  }

  const onEditClick = (id: string) => {
    setEditingConnector(connectors?.connectors?.find((connector) => connector.id === id))
    setEditConnectorModalOpen(true)
  }

  const handleEditCancel = () => {
    setEditConnectorModalOpen(false)
    setEditingConnector(undefined)
  }

  const handleEditModalClose = () => {
    refetch()
    setEditingConnector(undefined)
  }

  const onDeleteClick = (id: string) => {
    setEditingConnector(connectors?.connectors?.find((connector) => connector.id === id))
    setDeleteModalOpen(true)
  }

  const onEditLinksClick = (id: string) => {
    setEditingConnector(connectors?.connectors?.find((connector) => connector.id === id))
    setLinksModalOpen(true)
  }

  const handleDeleteConnector = () => {
    if (!org || !editingConnector) return
    deleteConnector(
      { id: editingConnector.id },
      {
        onSuccess: () => {
          setDeleteModalOpen(false)
          setEditingConnector(undefined)
        },
      },
    )
  }

  return (
    <div className='overflow-hidden flex flex-col h-full w-full'>
      <div className='flex flex-none items-center justify-between gap-x-16 border-b border-gray-300 px-8 py-3'>
        <div className='flex gap-x-16'>
          <p className='text-2xl'>{t('connectors')}</p>
          <div className='flex items-center gap-x-4'>
            <Button
              onClick={() => setCreateConnectorModalOpen(true)}
              className='text-blue-500 border border-blue-500 hover:bg-slate-50 active:!bg-slate-100 flex items-center gap-x-2'
            >
              <PlusIcon className='w-4 h-4 flex-none' />
              <>{t('Connectors.connector')}</>
            </Button>
          </div>
        </div>
        <PaginationController
          total={connectors?.total ?? 1}
          size={size}
          page={page + 1}
          onPageChange={(page) => setPage(page - 1)}
          onSizeChange={(size) => setSize(size)}
        />
      </div>
      <div className={`px-8 pt-3 py-1 grow overflow-hidden ${isLoading ? 'opacity-50' : 'opacity-100'}`}>
        <div className=' h-full overflow-auto relative'>
          {(isLoading || isPending) && (
            <div className='absolute first:w-full flex justify-center items-center pt-5'>
              <SpinnerIcon className='text-sky-500 h-7 w-7'></SpinnerIcon>
            </div>
          )}
          {/* {(!isLoading || !isPending) && (!connectors?.connectors || connectors.connectors.length === 0) ? (
            <p className='text-gray-500'>{t('Connectors.noConnectors')}</p>
          ) : ( */}
          <Table
            headers={headers}
            rows={newRows!}
            onEdit={onEditClick}
            onDelete={onDeleteClick}
            onEditLinks={onEditLinksClick}
            onNameSearch={setNameSearch}
            type='on-premise'
            onHeaderFilter={(field, value) =>
              setTableFilters((old) => {
                return {
                  ...old,
                  [field]: value ?? undefined,
                }
              })
            }
            filterValues={tableFilters}
          />
          {/* )} */}
        </div>
      </div>
      {createConnectorModalOpen && (
        <AddConnectorModal
          open={createConnectorModalOpen}
          setOpen={setCreateConnectorModalOpen}
          isAdding={addingConnector}
          onAdd={handleCreateConnector}
          options={options}
        />
      )}
      {editConnectorModalOpen && editingConnector && (
        <EditConnectorModal
          open={editConnectorModalOpen}
          setOpen={setEditConnectorModalOpen}
          onEditConfig={handleUpdateConnector}
          isUpdating={updatingConnector}
          options={options}
          editingConnector={editingConnector}
          handleEditCancel={handleEditCancel}
          onClose={handleEditModalClose}
        />
      )}
      {deleteModalOpen && (
        <DeleteModal
          title={t('Connectors.deleteConnector')}
          description={t('Connectors.removeMsg')}
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
          onDeleteConfirmed={handleDeleteConnector}
          isLoading={removingConnector}
        />
      )}
      {linksModalOpen && (
        <LinksModal
          open={linksModalOpen}
          setOpen={setLinksModalOpen}
          editingConnector={editingConnector!}
          onClose={handleEditModalClose}
        />
      )}
    </div>
  )
}

export default OnPremiseConnectorsPage
