import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { EditInput, ModalDialog, SpinnerIcon } from '@sistemiv/s-components'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOrgNodes } from '../../repositories/organization-nodes/org-nodes.repository'
import { OrgNode } from '../../models/OrganizationalNode'
import { useParams } from 'react-router-dom'
import OrgPositionCombobox from './OrgPositionCombobox'
import { useUpdateServiceAccName } from '../../repositories/service-accounts/mutation/update-service-acc-name.mutation'
import { useUpdateServiceAccNode } from '../../repositories/service-accounts/mutation/update-service-acc-node'

const EditServiceAccountModal: FC<{
  open: boolean
  setOpen: DispatchSetAction<boolean>
  editingAcc: any
  onCancel: () => void
}> = ({ open, setOpen, editingAcc, onCancel }) => {
  const [name, setName] = useState(editingAcc.name)
  const { org } = useParams()
  const { t } = useTranslation()
  const { data: orgNodes, isLoading } = useOrgNodes()
  const [selectedNode, setSelectedNode] = useState<OrgNode | undefined>(
    orgNodes?.results?.find((node) => node.id === editingAcc.organizationNodeId),
  )
  const { mutate: editName } = useUpdateServiceAccName()
  const { mutate: editNode, isPending: editingNode } = useUpdateServiceAccNode()

  const handleEditName = (name: string) => {
    editName(
      {
        name: name,
        id: editingAcc.id,
        organization: org!,
      },
      {
        onSuccess: () => {
          setName(name)
        },
      },
    )
  }
  const handleEditNode = (node: OrgNode) => {
    editNode(
      {
        node: node.id,
        id: editingAcc.id,
        organization: org!,
      },
      {
        onSuccess: () => {
          setSelectedNode(node)
        },
      },
    )
  }

  return (
    <ModalDialog isOpen={open} setOpen={setOpen} className='!max-w-sm'>
      <Dialog.Title as='h3' className='leading-6 text-lg flex items-center justify-between'>
        {t('ServiceAccounts.editAccount')}
        <button onClick={onCancel} className='rounded-full p-2 hover:bg-gray-100 active:text-blue-500'>
          <XMarkIcon className='w-5 h-5 stroke-gray-500 active:stroke-blue-500' />
        </button>
      </Dialog.Title>
      {isLoading ? (
        <div className='w-full flex items-center justify-center py-6'>
          <SpinnerIcon className='w-6 h-6 text-sky-500' />
        </div>
      ) : (
        <div className='pt-5 space-y-6'>
          <div className='space-y-1'>
            <label className='text-gray-500'>{t('ServiceAccounts.name')}</label>
            <EditInput value={name} onSave={(value) => org && handleEditName(value)} />
          </div>
          <div className='mt-3'>
            <p className='text-s pb-2 text-gray-600'>{t('ServiceAccounts.orgPosition')}</p>
            {orgNodes?.results && (
              <OrgPositionCombobox
                selected={selectedNode!}
                onChange={handleEditNode}
                options={orgNodes?.results.filter((node) => node.id !== selectedNode?.id)}
                loading={editingNode}
              />
            )}
          </div>
        </div>
      )}
    </ModalDialog>
  )
}

export default EditServiceAccountModal
