import React, { FC, useEffect, useState } from 'react'
import GroupsHeader from './GroupsHeader'
import { Base64SVG, Button, DeleteModal, IconPicker, Input, SpinnerIcon, ThrashBinIcon } from '@sistemiv/s-components'
import { Group } from '../../models/Group'
import { useNavigate, useParams } from 'react-router-dom'
import { PencilIcon } from '@heroicons/react/24/solid'
import { UserCircleIcon } from '@heroicons/react/24/outline'
import GroupModal from './GroupModal'
import GroupService from '../../services/GroupService'
import MembersModal from '../members/MembersModal'
import { useGroups } from '../../repositories'
import { useQueryClient } from '@tanstack/react-query'
import { initIcons } from '../../data'
import useLocalStorage from 'use-local-storage'
import { useTranslation } from 'react-i18next'
import { useCreateGroup } from '../../repositories/groups/mutations/groups-create'
import { useDeleteGroup } from '../../repositories/groups/mutations/groups-delete'
import { groupsEvents, SignalRContext } from '../../pages/Dashboard'

const GroupsPage: FC = () => {
  const { org } = useParams()
  const queryClient = useQueryClient()
  const [page, setPage] = useState(1)
  const [size, setSize] = useState(20)
  const [groupsModalOpen, setGroupsModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [membersModalOpen, setMembersModalOpen] = useState(false)
  const [editingGroup, setEditingGroup] = useState<Group | undefined>(undefined)
  const { mutate: createGroupMutation, isPending: creating } = useCreateGroup()
  const { mutate: deleteGroupMutation, isPending: removing } = useDeleteGroup()
  const [search, setSearch] = useState('')
  const [pendingSearch, setPendingSearch] = useState('')
  const { t } = useTranslation()

  const { data: groups, isLoading, isFetching } = useGroups({ page, size, search })

  const navigate = useNavigate()

  const [icons] = useLocalStorage('icons', initIcons)

  const handleGroupUpdate = (id: string, field: string, value: any) => {
    if (!org) return
    GroupService.update(org, id, field, { [field]: value.base64string }).then(() => {
      queryClient.invalidateQueries({ queryKey: ['groups', org] })
    })
  }

  const handleCreate = (icon: string, name: string) => {
    if (!org) return
    createGroupMutation(
      {
        organization: org,
        icon: icon,
        name: name,
      },
      {
        onSuccess: () => {
          setGroupsModalOpen(false)
        },
      },
    )
  }

  SignalRContext.useSignalREffect(
    'sync',
    //@ts-ignore
    (payload) => {
      if (groupsEvents.includes(payload)) {
        queryClient.invalidateQueries({ queryKey: ['groups', org] })
      }
    },
    [],
  )

  const handleEdit = (field: string, value: string) => {
    if (!org || !editingGroup) return
    GroupService.update(org, editingGroup.id, field, { [field]: value }).then(() => {
      queryClient.invalidateQueries({ queryKey: ['groups', org] })
    })
  }

  const handleDelete = () => {
    if (!org || !editingGroup) return
    deleteGroupMutation(
      {
        id: editingGroup.id,
      },
      {
        onSuccess: () => {
          setEditingGroup(undefined)
          setDeleteModalOpen(false)
        },
      },
    )
  }

  useEffect(() => {
    if (groups) {
      setEditingGroup((old) => {
        if (!old) return undefined
        const newGroup = groups.results.find((g) => g.id === old.id)
        return newGroup
      })
    }
  }, [groups])

  useEffect(() => {
    const delayTyping = setTimeout(() => {
      setSearch(pendingSearch)
    }, 500)

    return () => clearTimeout(delayTyping)
  }, [pendingSearch])

  if (isLoading)
    return (
      <div className='w-full flex justify-center pt-5'>
        <SpinnerIcon className='text-sky-500 h-7 w-7'></SpinnerIcon>
      </div>
    )

  return (
    <div className='flex flex-col h-full overflow-hidden'>
      <GroupsHeader
        total={groups?.total ?? 0}
        page={page}
        size={size}
        onPageChange={setPage}
        onSizeChange={(value) => {
          if (groups && value >= groups?.total) {
            setPage(1)
          }
          setSize(value)
        }}
        onCreateGroup={() => {
          setEditingGroup(undefined)
          setGroupsModalOpen(true)
        }}
      />
      <div className={`p-8 ${isFetching ? 'opacity-50' : 'opacity-100'} grow overflow-auto`}>
        {((groups && groups?.results.length > 0) || search !== '') && (
          <div className='w-1/4 pb-6'>
            <Input
              id={'search'}
              type={'search'}
              classes='!rounded-md [&>input]:!border-solid [&>input]:border [&>input]:border-gray-300 focus:[&>input]:border-sky-100'
              placeholder={t('Groups.search') as string}
              value={pendingSearch}
              onChange={(e) => setPendingSearch(e.target.value)}
            />
          </div>
        )}
        {groups?.results?.length ? (
          groups?.results?.map((group) => (
            <div className='group grid grid-cols-2 gap-x-20 py-1.5 pl-10 pr-4 hover:bg-blue-50 w-1/2' key={group.id}>
              <div className='flex items-center gap-x-3 '>
                <IconPicker setSelected={(icon: any) => handleGroupUpdate(group.id, 'icon', icon)} options={icons}>
                  <div className='flex items-center justify-center p-1'>
                    <div className='rounded-full bg-[#6275A5] p-1.5 aspect-square hover:ring-1 hover:ring-offset-2 hover:ring-gray-800'>
                      <Base64SVG base64image={group.icon} className='[&>svg>*]:fill-white [&>svg]:h-6 [&>svg]:w-6' />
                    </div>
                  </div>
                </IconPicker>
                <span className='whitespace-nowrap text-ellipsis overflow-hidden'>{group.name}</span>
              </div>
              <div className='flex items-center justify-end align-end gap-x-5 opacity-0 group-hover:opacity-100'>
                <Button
                  className='!p-0'
                  onClick={() => {
                    setEditingGroup(group)
                    setGroupsModalOpen(true)
                  }}
                >
                  <PencilIcon className='w-4 h-4 fill-slate-500 hover:fill-gray-800' />
                </Button>
                <Button
                  className='!p-0'
                  onClick={() => {
                    setEditingGroup(group)
                    setMembersModalOpen(true)
                  }}
                >
                  <UserCircleIcon className='w-5 h-5 text-slate-500 hover:text-gray-800' />
                </Button>
                <Button
                  className='!p-0'
                  onClick={() => {
                    setEditingGroup(group)
                    setDeleteModalOpen(true)
                  }}
                >
                  <ThrashBinIcon className='w-4 h-4 fill-slate-500 hover:fill-gray-800' />
                </Button>
                <Button className='!p-0 text-blue-500' onClick={() => navigate(`/${org}/groups/${group.id}`)}>
                  {t('Groups.seeMembers')}
                </Button>
              </div>
            </div>
          ))
        ) : (
          <p className='text-gray-500'>{t('Groups.noGroups')}</p>
        )}
      </div>
      {groupsModalOpen && (
        <GroupModal
          open={groupsModalOpen}
          setOpen={setGroupsModalOpen}
          onCreate={handleCreate}
          onEdit={handleEdit}
          group={editingGroup}
          loading={creating}
        />
      )}
      {deleteModalOpen && (
        <DeleteModal
          title={t('Groups.deleteGroup')}
          description={t('Groups.deleteMsg')}
          open={deleteModalOpen}
          setOpen={setDeleteModalOpen}
          onDeleteConfirmed={handleDelete}
          isLoading={removing}
        />
      )}
      {membersModalOpen && (
        <MembersModal
          open={membersModalOpen}
          setOpen={setMembersModalOpen}
          groupId={editingGroup ? editingGroup.id : undefined}
          groupName={editingGroup ? editingGroup.name : undefined}
        />
      )}
    </div>
  )
}

export default GroupsPage
