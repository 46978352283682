import { useQuery, keepPreviousData } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import OnPremiseConnectorsService from '../../services/OnPremiseConnectors.service'
import { ConnectorsResponse } from '../../models/Connectors'

type connectorsOptions = {
  page: number
  size: number
  name?: string
  types?: string[]
  states?: string[]
}

export const useOnPremiseConnectors = ({ page, size, name, types, states }: connectorsOptions) => {
  const { org } = useParams()
  return useQuery<ConnectorsResponse>({
    queryKey: ['on-premise-connectors', org, { page, size, name, types, states }],
    queryFn: () => OnPremiseConnectorsService.listOnPremiseConnectors(org!, page, size, name, types, states),
    placeholderData: keepPreviousData,
    enabled: !!org,
  })
}
