import React, { FC, useEffect, useState } from 'react'
import DataField from '../table-header/DataField'
import { Button, Input, SpinnerIcon, ThrashBinIcon, Tooltip } from '@sistemiv/s-components'
import { useTranslation } from 'react-i18next'
import { TableHeaders, TableRow } from '../table/Table'
import { DocumentDuplicateIcon, PencilIcon } from '@heroicons/react/24/outline'
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid'
import TableHeaderCombobox from '../table-header/TableHeaderCombobox'
import { useCredentials } from '../../repositories/service-accounts'

type TableProps = {
  headers: TableHeaders[]
  rows: TableRow[]
  onHeaderFilter?: (field: string, value: any) => void
  refreshTable?: () => void
  onEdit: (id: string) => void
  onDelete: (id: string) => void
  onNameSearch: (value: string) => void
  filterValues: { [key: string]: any }
}

const Table: FC<TableProps> = ({ headers, rows, onEdit, onDelete, onNameSearch, filterValues, onHeaderFilter }) => {
  const [tableData, setTableData] = useState<TableRow[]>(rows)
  const { t } = useTranslation()
  const [pendingNameSearch, setPendingNameSearch] = useState('')

  useEffect(() => {
    setTableData(rows)
  }, [rows])

  useEffect(() => {
    const delayTyping = setTimeout(() => {
      onNameSearch(pendingNameSearch)
    }, 500)

    return () => clearTimeout(delayTyping)
  }, [pendingNameSearch, onNameSearch])

  return (
    <>
      <table className='min-w-full divide-y divide-gray-200 border-collapse'>
        <thead className='text-sm bg-white'>
          <tr className='border-b border-gray-300'>
            {headers?.map((header, index) => (
              <th
                data-testid='table-header'
                key={index}
                scope='col'
                className='px-6 py-3 text-left font-normal text-slate-600 capitalize tracking-wider cursor-pointer'
              >
                <div className='inline-flex items-center justify-start gap-x-3 '>{header.label}</div>
              </th>
            ))}
          </tr>
          <tr className='border-b border-gray-300'>
            {headers?.map((header, index) => (
              <th
                key={index}
                scope='col'
                className='px-6 py-3 text-left font-medium text-gray-500 min-w-[12rem] tracking-wider'
                data-testid='filter'
              >
                {header.searchType === 'search' ? (
                  <div>
                    <Input
                      id={'search'}
                      type={'search'}
                      classes='!rounded-md [&>input]:!border-solid [&>input]:border [&>input]:border-gray-300 focus:[&>input]:border-sky-100 pr-16 max-w-72'
                      placeholder={t('ServiceAccounts.search') as string}
                      value={pendingNameSearch}
                      onChange={(e) => setPendingNameSearch(e.target.value)}
                    />
                  </div>
                ) : header.searchType === 'select' ? (
                  <div>
                    <TableHeaderCombobox
                      options={
                        header.options?.map((h) => {
                          if (typeof h === 'string') {
                            return {
                              type: 'plainText',
                              value: h,
                            }
                          } else if (typeof h === 'object' && h.id) {
                            return {
                              type: 'plainText',
                              id: h.id,
                              value: h.name,
                            }
                          } else {
                            return {
                              type: 'plainText',
                              value: '',
                            }
                          }
                        }) ?? []
                      }
                      value={filterValues[header.accessor] ?? []}
                      selectFor={''}
                      onChange={(val) => onHeaderFilter && onHeaderFilter(header.accessor, val)}
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className='bg-white'>
          {tableData?.map((row, index) => (
            <tr key={index} className='border-b border-gray-300 group hover:bg-sky-50' data-testid='row'>
              {headers?.map((header, index) => {
                const field = header.accessor !== 'actions' ? row[header.accessor] : null
                if (header.accessor === 'actions') {
                  return (
                    <td
                      key={index}
                      className='px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-start'
                      data-testid='table-field'
                    >
                      <div className='flex items-center'>
                        <Button
                          className='!p-0 mr-3'
                          onClick={() => {
                            onEdit(row['id']?.value)
                          }}
                        >
                          <PencilIcon className='w-4 h-4 text-gray-500 hover:text-gray-800' />
                        </Button>
                        <Button
                          className='!p-0'
                          onClick={() => {
                            onDelete(row['id']?.value)
                          }}
                        >
                          <ThrashBinIcon className='w-4 h-4 fill-slate-500 hover:fill-gray-800' />
                        </Button>
                      </div>
                    </td>
                  )
                } else if (header.accessor === 'credentialsValue') {
                  return (
                    <td
                      key={index}
                      className='px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-start'
                      data-testid='table-field'
                    >
                      <SecretField id={row['id']?.value} />
                    </td>
                  )
                } else {
                  return (
                    <td
                      key={index}
                      className='px-6 py-3 whitespace-nowrap text-sm text-gray-500 text-start'
                      data-testid='table-field'
                    >
                      <DataField field={field!} />
                    </td>
                  )
                }
              })}
              <td className='p-3'></td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  )
}

type FieldProps = {
  id: string
}
const SecretField: FC<FieldProps> = ({ id }) => {
  const hiddenValue = '************************'
  const [value, setValue] = useState('')
  const [show, setShow] = useState(false)
  const { data: credentials, refetch, isLoading, isFetching } = useCredentials(id)
  const [copied, setCopied] = useState(false)

  const handleShow = async (show: boolean) => {
    setShow(show)
    if (!show) return
    !credentials &&
      refetch().then((res) => {
        setValue(res?.data.credentialValue)
      })
  }

  return (
    <div className='flex px-2 py-1 items-center'>
      {isLoading || isFetching ? (
        <div className=' flex justify-center items-center pt-1'>
          <SpinnerIcon className='text-sky-500 h-4 w-4'></SpinnerIcon>
        </div>
      ) : (
        <Tooltip text={show && value ? value : ''} tooltipClassName='!top-full !bottom-auto'>
          <div className='mr-2 whitespace-nowrap text-ellipsis overflow-hidden w-24'>{show ? value : hiddenValue}</div>
          {show && value && (
            <button
              className={`opacity-0 group-hover:opacity-100 group/button mr-2`}
              onClick={() => {
                navigator.clipboard.writeText(value)
                setCopied(true)
                setTimeout(() => setCopied(false), 1000)
              }}
            >
              <DocumentDuplicateIcon
                className={`w-5 h-5 transition-all duration-200 text-slate-400 group-hover/button:text-slate-500 ${
                  copied ? '!text-sky-500' : ''
                }`}
              />
            </button>
          )}
          <Button className='!px-0 opacity-0 group-hover:opacity-100 group/button' onClick={() => handleShow(!show)}>
            {show ? <EyeIcon className='w-5 h-5' /> : <EyeSlashIcon className='w-5 h-5' />}
          </Button>
        </Tooltip>
      )}
    </div>
  )
}

export default Table
