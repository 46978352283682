import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { Button, EditInput, ModalDialog, Option, Select, SpinnerIcon } from '@sistemiv/s-components'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Connector } from '../../models/Connectors'
import OnPremiseConnectorsService from '../../services/OnPremiseConnectors.service'
import { useParams } from 'react-router-dom'
import { useConnector } from '../../repositories/connectors/connector.respository'

const EditConnectorModal: FC<{
  open: boolean
  setOpen: DispatchSetAction<boolean>
  options: string[]
  onEditConfig: (type: string) => void
  isUpdating: boolean
  handleEditCancel?: () => void
  editingConnector: Connector
  onClose: () => void
}> = ({ open, setOpen, options, editingConnector, handleEditCancel, onClose, isUpdating, onEditConfig }) => {
  const { org } = useParams()
  const { data: connector, isLoading } = useConnector(editingConnector?.id)
  const [name, setName] = useState(editingConnector?.name)
  const { t } = useTranslation()
  const [selectedType, setSelectedType] = useState(connector?.configurationType)

  const handleNameUpdate = (value: string) => {
    if (!editingConnector) return
    OnPremiseConnectorsService.editConnectorName(org!, editingConnector?.id, value).then(() => {
      setName(value)
    })
  }

  return (
    <ModalDialog isOpen={open} setOpen={setOpen} className='!max-w-sm'>
      <Dialog.Title as='h3' className='leading-6 text-lg flex items-center justify-between'>
        {t('Connectors.editConnector')}
        <button
          onClick={() => {
            onClose()
            setOpen(false)
          }}
          className='rounded-full p-2 hover:bg-gray-100 active:text-blue-500'
        >
          <XMarkIcon className='w-5 h-5 stroke-gray-500 active:stroke-blue-500' />
        </button>
      </Dialog.Title>
      <div className='pt-5 space-y-6'>
        <div className='mt-3'>
          <p className='text-s pb-2 text-gray-600'>{t('Connectors.name')}</p>
          <EditInput value={name} onSave={(value) => handleNameUpdate(value)} />
        </div>
        {isLoading && (
          <div className='first:w-full flex justify-center items-center pt-5'>
            <SpinnerIcon className='text-sky-500 h-7 w-7'></SpinnerIcon>
          </div>
        )}
        {connector && (
          <div className='border border-gray-300 p-4 !rounded-md'>
            <div>
              <p className='text-s pb-2 text-gray-600'>{t('Connectors.type')}</p>
              <Select
                disabled={options.length === 0}
                className='relative rounded-md border border-gray-300 w-full h-full'
                value={selectedType}
                displayValue={selectedType}
                onChange={(v) => setSelectedType(v)}
              >
                {options.map((option) => (
                  <Option value={option} key={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </div>
            {/* TO DO: dodati ostala polja za konfiguraciju kad se definisu */}
            <div className='flex items-center justify-end gap-x-3 mt-4'>
              <Button onClick={handleEditCancel} className='hover:bg-gray-100 active:text-blue-500'>
                {t('Connectors.cancel')}
              </Button>
              <Button
                // TO DO: change edit function to accept configuration values
                onClick={() => onEditConfig && onEditConfig(selectedType!)}
                disabled={!selectedType}
                className='text-white bg-blue-500 disabled:bg-slate-200 disabled:text-slate-400'
                loading={isUpdating}
              >
                {t('Connectors.update')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </ModalDialog>
  )
}

export default EditConnectorModal
