import { Button, EditInput, Input, ThrashBinIcon } from '@sistemiv/s-components'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from '../../models/Connectors'
import OnPremiseConnectorsService from '../../services/OnPremiseConnectors.service'
import { useParams } from 'react-router-dom'

const LinkForm: FC<{
  onAdd?(name: string, nodeId: string): void
  isAdding?: boolean
  editingLink?: Link
  connectorId: string
  onCreate?: (linkValues, connectorId: string) => void
  onDelete?: (connectorId: string, id: string) => void
}> = ({ onCreate, onDelete, editingLink, connectorId }) => {
  const { org } = useParams()
  const [name, setName] = useState(editingLink?.name ?? '')
  const [fqdn, setFqdn] = useState(editingLink?.fqdn ?? '')
  const [host, setHost] = useState(editingLink?.host ?? '')
  const [path, setPath] = useState(editingLink?.path ?? '')
  const { t } = useTranslation()
  const mode = editingLink?.id ? 'edit' : 'create'

  const onUpdateLink = (field: string, value: any) => {
    if (!editingLink || !field) return
    const body = {}
    switch (field) {
      case 'name':
        body['name'] = value
        break
      case 'host':
        body['host'] = value.base64string
        break
      case 'path':
        body['path'] = value
        break
      case 'fqdn':
        body['fqdn'] = value
        break
      default:
        return
    }

    OnPremiseConnectorsService.updateLink(org!, connectorId, editingLink.id, field, body).then(() => {
      console.log('success')
      switch (field) {
        case 'name':
          setName(value)
          break
        case 'host':
          setHost(value)
          break
        case 'path':
          setPath(value)
          break
        case 'fqdn':
          setFqdn(value)
          break
        default:
          return
      }
    })
  }

  return (
    <div className='flex flex-row mt-3'>
      <div>
        <div className='pt-5 flex space-x-3'>
          <div>
            <p className='text-s pb-2 text-gray-600'>{t('Connectors.name')}</p>
            {mode === 'edit' ? (
              <EditInput value={name} onSave={(value) => onUpdateLink('name', value)} className='!min-w-[250px]' />
            ) : (
              <Input
                id='name'
                type='text'
                removePencil
                classes='!rounded-md !border-solid border border-gray-300 focus:border-sky-100 !min-w-[250px]'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            )}
          </div>
          <div>
            <p className='text-s pb-2 text-gray-600'>FQDN</p>
            {mode === 'edit' ? (
              <EditInput value={fqdn} onSave={(value) => onUpdateLink('fqdn', value)} className=' !min-w-[400px]' />
            ) : (
              <Input
                id='fqdn'
                type='text'
                removePencil
                classes='!rounded-md !border-solid border border-gray-300 focus:border-sky-100 !min-w-[400px]'
                value={fqdn}
                onChange={(e) => setFqdn(e.target.value)}
              />
            )}
          </div>
        </div>
        <div className='pt-3 flex space-x-3'>
          <div>
            <p className='text-s pb-2 text-gray-600'>{t('Connectors.host')}</p>
            {mode === 'edit' ? (
              <EditInput value={host} onSave={(value) => onUpdateLink('host', value)} className='!min-w-[250px]' />
            ) : (
              <Input
                id='host'
                type='text'
                removePencil
                classes='!rounded-md !border-solid border border-gray-300 focus:border-sky-100 !min-w-[250px]'
                value={host}
                onChange={(e) => setHost(e.target.value)}
              />
            )}
          </div>
          <div>
            <p className='text-s pb-2 text-gray-600'>{t('Connectors.path')}</p>
            {mode === 'edit' ? (
              <EditInput value={path} onSave={(value) => onUpdateLink('path', value)} className=' !min-w-[400px]' />
            ) : (
              <Input
                id='path'
                type='text'
                removePencil
                classes='!rounded-md !border-solid border border-gray-300 focus:border-sky-100 !min-w-[400px]'
                value={path}
                onChange={(e) => setPath(e.target.value)}
              />
            )}
          </div>
        </div>
      </div>
      {mode === 'edit' && editingLink ? (
        <Button
          className='!pt-6 !pb-0 ml-6'
          onClick={() => {
            onDelete && onDelete(connectorId, editingLink?.id)
          }}
        >
          <ThrashBinIcon className='w-6 h-6 fill-slate-500 hover:fill-gray-800' />
        </Button>
      ) : (
        <Button
          className={`!pt-6 !pb-0 ml-6 ${!name || !host || !path || !fqdn ? 'text-gray-500' : 'text-blue-600'}`}
          onClick={() => onCreate && onCreate({ name, path, host, fqdn }, connectorId)}
          disabled={!name || !host || !path || !fqdn}
        >
          Add
        </Button>
      )}
    </div>
  )
}

export default LinkForm
