import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { ConnectorConfig } from '../../models/Connectors'
import OnPremiseConnectorsService from '../../services/OnPremiseConnectors.service'

export const useConnector = (id: string) => {
  const { org } = useParams()
  return useQuery<ConnectorConfig>({
    queryKey: ['connector', org, id],
    queryFn: () => OnPremiseConnectorsService.getConnectorById(org!, id),
    enabled: !!org && !!id,
  })
}
