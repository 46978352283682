import { SideMenu, SideMenuItem } from '@sistemiv/s-components'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

const SideNav: FC = () => {
  const { org, nav } = useParams()
  const { t } = useTranslation()

  const navigate = useNavigate()

  const navItems: {
    key: string
    name: string
  }[] = [
    {
      key: 'users',
      name: t('users'),
    },
    {
      key: 'service-accounts',
      name: t('serviceAccounts'),
    },
    {
      key: 'connectors',
      name: t('connectors'),
    },
    // {
    //   key: 'auth-connectors',
    //   name: t('authConnectors'),
    // },
    {
      key: 'groups',
      name: t('groups'),
    },
    {
      key: 'organizational-chart',
      name: t('orgChart'),
    },
    // {
    //   key: 'products',
    //   name: 'Products',
    // },
    // {
    //   key: 'subscriptions',
    //   name: 'Subscriptions',
    // },
    // {
    //   key: 'billing',
    //   name: 'Billing',
    // },
    {
      key: 'company-profile',
      name: t('companyProfile'),
    },
    {
      key: 'settings',
      name: t('settings'),
    },
  ]
  return (
    <SideMenu isOpen={true}>
      {navItems?.map((item) => (
        <SideMenuItem
          onClick={() => navigate(`/${org}/${item.key}`)}
          key={item.key}
          classes={`flex items-center gap-x-3 py-3 px-8 ${
            nav === item.key ? 'text-blue-500 bg-blue-50 hover:!bg-blue-50' : 'text-gray-700 bg-white'
          }`}
        >
          <p>{item.name}</p>
        </SideMenuItem>
      ))}
    </SideMenu>
  )
}

export default SideNav
