import { Dialog } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { Button, Input, ModalDialog, Option, Select } from '@sistemiv/s-components'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

const AddConnectorModal: FC<{
  open: boolean
  setOpen: DispatchSetAction<boolean>
  onAdd?(name: string, type: string): void
  options: string[]
  isAdding?: boolean
}> = ({ open, setOpen, onAdd, isAdding, options }) => {
  const [name, setName] = useState('')
  const { t } = useTranslation()
  const [selectedType, setSelectedType] = useState(options[0])

  return (
    <ModalDialog isOpen={open} setOpen={setOpen} className='!max-w-sm'>
      <Dialog.Title as='h3' className='leading-6 text-lg flex items-center justify-between'>
        {t('Connectors.addConnector')}
        <button onClick={() => setOpen(false)} className='rounded-full p-2 hover:bg-gray-100 active:text-blue-500'>
          <XMarkIcon className='w-5 h-5 stroke-gray-500 active:stroke-blue-500' />
        </button>
      </Dialog.Title>
      <div className='pt-5 space-y-6'>
        <div className='mt-3'>
          <p className='text-s pb-2 text-gray-600'>{t('Connectors.name')}</p>
          <Input
            id='name'
            type='text'
            removePencil
            classes='!rounded-md !border-solid border border-gray-300 focus:border-sky-100'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className='mt-3'>
          <p className='text-s pb-2 text-gray-600'>{t('Connectors.type')}</p>
          <Select
            disabled={options.length === 1}
            className='relative rounded-md border border-gray-300 w-full h-full'
            value={selectedType}
            displayValue={selectedType}
            onChange={(v) => setSelectedType(v)}
          >
            {options.map((option) => (
              <Option value={option} key={option}>
                {option}
              </Option>
            ))}
          </Select>
        </div>
        <div className='flex items-center justify-end gap-x-3'>
          <Button onClick={() => setOpen(false)} className='hover:bg-gray-100 active:text-blue-500'>
            {t('Connectors.cancel')}
          </Button>
          <Button
            onClick={() => onAdd && onAdd(name, selectedType)}
            disabled={name === '' || !selectedType || isAdding}
            className='text-white bg-blue-500 disabled:bg-slate-200 disabled:text-slate-400'
            loading={isAdding}
          >
            {t('Connectors.create')}
          </Button>
        </div>
      </div>
    </ModalDialog>
  )
}

export default AddConnectorModal
