import { Dialog } from '@headlessui/react'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { ModalDialog } from '@sistemiv/s-components'
import { DispatchSetAction } from '@sistemiv/s-components/dist/esm/types/types'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

const ErrorModal: FC<{
  open: boolean
  setOpen: DispatchSetAction<boolean>
  connectorName: string
  errorMsg: string
}> = ({ open, setOpen, connectorName, errorMsg }) => {
  const { t } = useTranslation()

  return (
    <ModalDialog isOpen={open} setOpen={setOpen} className='!max-w-lg'>
      <Dialog.Title as='h3' className='leading-6 text-lg flex items-center justify-between'>
        <div className='flex items-center'>
          <ExclamationCircleIcon className='w-8 h-8 text-red-400 mr-5' />
          {t('Connectors.errorModalTitle')}
        </div>
        <button onClick={() => setOpen(false)} className='rounded-full p-2 hover:bg-gray-100 active:text-blue-500'>
          <XMarkIcon className='w-5 h-5 stroke-gray-500 active:stroke-blue-500' />
        </button>
      </Dialog.Title>
      <div className='pt-5 space-y-6 flex flex-col'>
        <div className='mt-3 flex space-x-5'>
          <p className='text-s pb-2 text-gray-600 flex-wrap'>{t('Connectors.connector')}</p>
          <p>{connectorName}</p>
        </div>
        <div className='mt-3 flex'>
          <p className='text-s pb-2 text-gray-600 mr-14'>{t('Connectors.error')}</p>
          <p className='break-words'>{errorMsg}</p>
        </div>
      </div>
    </ModalDialog>
  )
}

export default ErrorModal
